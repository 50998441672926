//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionTemplate from './SectionTemplate.vue'
export default {
  extends: SectionTemplate
}
